import { createContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { Toaster } from "react-hot-toast";
import { ScaleLoader } from "react-spinners";
import { get, post } from "./utils/api";
import Trucks from "./pages/Dashboard/Trucks";
import Cargos from "./pages/Dashboard/Cargos";
import Drivers from "./pages/Dashboard/Drivers";
import { useInterval } from "./hooks/useInterval";
import Users from "./pages/Dashboard/Users";
import StoredTruck from "./pages/Dashboard/StoredTruck";
import Navbar from "./components/Navbar";

export const AppContext = createContext();

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState({ lat: "", lng: "" });
  const [trucks, setTrucks] = useState(null);
  const [users, setUsers] = useState(null);
  const [truckList, setTruckList] = useState(null);
  const [cargoList, setCargoList] = useState(null);
  const [driverList, setDriverList] = useState(null);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [filteredData, setFilteredData] = useState(trucks);
  const [zoomOut, setZoomOut] = useState(false);
  const [filteredIds, setFilteredIds] = useState(null);
  const [filterActive, setFilterActivate] = useState(false);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  const fetchUser = async () => {
    try {
      const res = await get("/get-user");
      // console.log(res.data);
      setUser(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getTrucks = async () => {
    const response = await get("/get-trucks");
    if (user?.role === "user") {
      if (!user?.truckCode) return setTrucks(null);

      setTrucks(
        response.data.filter((el) => user?.truckCode.includes(el?.lmsg.unit_id))
      );
    } else {
      setTrucks(response.data);
    }
  };

  useInterval(() => {
    getTrucks();
  }, 2000);

  const getTrucksForFilter = async () => {
    const response = await get("/get-trucks");
    if (user?.role === "user") {
      if (!user?.truckCode) return setFilteredData(null);
      console.log(user?.truckCode);
      setFilteredData(
        response.data.filter((el) =>
          user?.truckCode.includes(el?.lmsg.unit_id.toString())
        )
      );
    } else {
      setFilteredData(response.data);
    }
  };
  // console.log(filteredData);

  const getUsers = async () => {
    const response = await get("/users");
    setUsers(response);
  };

  const getTruckList = async () => {
    const response = await get("/truck");
    if (user?.role === "user" && user?.truckCode)
      return setTruckList(
        response?.filter((t) => user?.truckCode.includes(t?.truckCode))
      );

    setTruckList(response);
  };

  const getCargoList = async () => {
    const response = await get("/cargo");
    if (user?.role === "user" && user?.truckCode)
      return setCargoList(
        response?.filter((c) => user?.truckCode.includes(c?.truckCode))
      );
    else setCargoList(response);
  };

  const getDriverList = async () => {
    const response = await get("/driver");
    if (user?.role === "user" && user?.truckCode)
      return setDriverList(
        response?.filter((d) => user?.truckCode.includes(d?.truckCode))
      );
    else setDriverList(response);
  };

  useEffect(() => {
    if (!filterActive) return setFilteredData(trucks);
    // eslint-disable-next-line
  }, [trucks]);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    getTrucksForFilter();
    getTrucks();
    getTruckList();
    getCargoList();
    getDriverList();
    getUsers();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (trucks && truckList) {
      setFilteredIds(
        trucks.filter((el) => {
          return !truckList.find((c) => {
            // eslint-disable-next-line
            return el.lmsg.unit_id == c.truckCode && el.lmsg.unit_id;
          });
        })
      );
    }
    // eslint-disable-next-line
  }, [truckList]);

  const sendIds = async () => {
    for (const el of filteredIds) {
      post(`/truck`, { truckCode: el.hw_id, pullerNumber: el.name });
      post(`/cargo`, { truckCode: el.hw_id });
      post(`/driver`, { truckCode: el.hw_id });
    }
  };

  useEffect(() => {
    if (filteredIds && user?.role === "admin") {
      sendIds();
    }
    // eslint-disable-next-line
  }, [filteredIds]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        position,
        trucks,
        truckList,
        setTruckList,
        setCargoList,
        setDriverList,
        setTrucks,
        cargoList,
        driverList,
        getCargoList,
        getTruckList,
        getDriverList,
        selectedTruck,
        setSelectedTruck,
        filteredData,
        setFilteredData,
        users,
        setUsers,
        getUsers,
        zoomOut,
        setZoomOut,
        filterActive,
        setFilterActivate,
        filter,
        setFilter,
      }}
    >
      {loading ? (
        <div className="loading d-flex justify-content-center align-items-center vh-100">
          <ScaleLoader color={"#3f5efa"} loading={loading} size={150} />
        </div>
      ) : (
        <div className="App">
          <Toaster />

          {user && (
            <div className="navbar-section">
              <Navbar />
            </div>
          )}

          <main
            style={{
              marginLeft: user ? "225px" : "0",
              width: user ? "calc(100% - 225px)" : "100%",
              paddingTop: user ? "170px" : "0",
            }}
          >
            {user && <Header />}

            <Routes>
              <Route
                path="/login"
                element={user ? <Navigate to="/" replace /> : <Login />}
              />
              <Route
                path="/register"
                element={user ? <Navigate to="/" replace /> : <Register />}
              />
              <Route
                exact
                path="/"
                element={
                  user ? (
                    <Home trucks={trucks} />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/dashboard"
                element={
                  user?.role === "admin" ||
                  (user?.role === "user" && user?.truckCode) ? (
                    <Navigate to="/dashboard/trucks" replace />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/dashboard/trucks"
                element={
                  user?.role === "admin" ||
                  (user?.role === "user" && user?.truckCode) ? (
                    <Trucks />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/dashboard/cargos"
                element={
                  user?.role === "admin" ||
                  (user?.role === "user" && user?.truckCode) ? (
                    <Cargos />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/dashboard/drivers"
                element={
                  user?.role === "admin" ||
                  (user?.role === "user" && user?.truckCode) ? (
                    <Drivers />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/dashboard/users"
                element={
                  user?.role === "admin" ? (
                    <Users />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route
                path="/dashboard/stored-truck"
                element={
                  user?.role === "admin" ? (
                    <StoredTruck />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
            </Routes>
          </main>
        </div>
      )}
    </AppContext.Provider>
  );
}

export default App;
