const setValue = async (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const getValue = async (key) => {
    const value = JSON.parse(localStorage.getItem(key));
    return value;
};

const removeValue = async (key) => {
    localStorage.removeItem(key);
};

export {
    setValue,
    getValue,
    removeValue
}