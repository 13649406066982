import { useContext } from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../../App';
import { EyeSlashIcon } from '../../components/Icons/Icons';
import { post } from '../../utils/api';
import { setValue } from '../../utils/storage';
import './style.css'

const Register = () => {
    const { setUser } = useContext(AppContext);
    const [passType, setPassType] = useState(true);
    const [prefix, setPrefix] = useState('');
    const [formData, setFormData] = useState({
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        password: ""
    });

    const handleSubmit = async () => {
        try {
            const res = await post('/register', { ...formData, phoneNumber: prefix + formData.phoneNumber })
            setUser(res.data)
            setValue("@token", res.access_token)
        }
        catch (err) {

        }
    }

    console.log(formData)
    return (
        <div id='Register' className='d-flex justify-content-center align-items-center'>

            <div className="col-md-3 p-2 h-100 d-none d-md-block">
                <div className='w-100 h-100 bg-current rounded-3'>

                </div>
            </div>
            <div className="col-md-9 col-12 d-flex justify-content-center align-items-center">
                <div className="auth-box px-2 py-3">
                    <h5 className='text-center mb-5'>Qeydiyyat</h5>

                    <div className="form-group mt-3">
                        <input type="text" className="form-control" id="firstName" placeholder="Adınız"
                            value={formData.firstName}
                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <input type="text" className="form-control" id="lastName" placeholder="Soyadınız"
                            value={formData.lastName}
                            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <input type="email" className="form-control" id="email" placeholder="E-mail"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <select name="number" id="" className='form-select' onChange={(e) => setPrefix(e.target.value)}>
                            <option value="010">(010)</option>
                            <option value="050">(050)</option>
                            <option value="051">(051)</option>
                            <option value="055">(055)</option>
                            <option value="070">(070)</option>
                            <option value="077">(077)</option>
                            <option value="099">(099)</option>
                        </select>
                        <input type="number" className="form-control" id="phoneNumber" placeholder="555 55 55"
                            value={formData.phoneNumber}
                            onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <input
                            type={passType ? "password" : "text"}
                            className="form-control"
                            id="password"
                            placeholder="Şifrə"
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        />
                        <img
                            src={EyeSlashIcon}
                            alt="hidden and show password icon"
                            className='icon'
                            onClick={() => setPassType(!passType)}
                        ></img>
                    </div>

                    <button className='bg-current px-3 w-100 mt-5'
                        onClick={() => handleSubmit()}
                    >
                        Qeydiyyatdan keç
                    </button>


                    <span className='mt-3 d-block text-center'>Hesabın varsa <Link to='/login' style={{ color: "#FF7B51" }}>Giriş et</Link></span>

                </div>
            </div>
        </div>
    )
}

export default Register