import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { AppContext } from "../../../App";
import CRUDModal from "../../../components/CRUDModal";
import Table from "../../../components/Table";
import { del, post, put } from "../../../utils/api";

import "../style.css";

const initialValue = {
  truckCode: "",
  firstName: "",
  lastName: "",
  civic: "",
  birth: "",
  phoneNumber: "",
  drivingLicenseNumber: "",
  idCardNumber: "",
  drivingLicenseLastDate: "",
  passportNumber: "",
  passportLastDate: "",
};
const names = [
  "truckCode",
  "firstName",
  "lastName",
  "civic",
  "birth",
  "phoneNumber",
  "drivingLicenseNumber",
  "idCardNumber",
  "drivingLicenseLastDate",
  "passportNumber",
  "passportLastDate",
];

const placeholders = [
  "Sürücüsü olduğu TIR-ın kodu",
  "Sürücünün adı",
  "Sürücünün Soyadı",
  "Vətəndaşlığı",
  "Təvəllüdü",
  "Əlaqə nömrəsi",
  "Sürücülük vəsiqəsinin nömrəsi",
  "Şəxsiyyət vəsiqəsinin nömrəsi",
  "Sürücülük vəsiqəsinin bitmə tarixi",
  "Xarici passportunun nömrəsi",
  "Xarici passportunun bitmə tarixi",
];

const Drivers = () => {
  const { driverList, user, getDriverList } = useContext(AppContext);
  const [isOpen, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(initialValue);

  const [selectedItem, setSelectedItem] = useState([]);
  const handleDelete = async () => {
    try {
      await del(`/driver`, selectedItem);
      getDriverList();
      setSelectedItem([]);
      toast.success(`Uğurla silindi!`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async () => {
    await setEdit(true);
    await setFormData(driverList.find((item) => item._id === selectedItem[0]));
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
    setFormData(initialValue);
    setEdit(false);
  };

  const handleSubmit = async () => {
    handleCancel();
    try {
      if (edit) {
        await put(`/driver`, { id: selectedItem[0], data: formData });
        getDriverList();
        return toast.success(`Uğurla dəyişdirildi!`);
      }
      await post(`/driver`, formData);
      setFormData(initialValue);
      getDriverList();
      toast.success(`Uğurla əlavə olundu!`);
    } catch (error) {
      toast.error(error.message);
    }
  };


  return (
    <div id="Dashboard" className="px-3">
      <div className="add-buttons py-3">
        {user?.role === "admin" && (
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="bg-current px-3 py-2 h-auto font-14"
              onClick={() => !edit && setOpen(true)}
            >
              <i className="fa-solid fa-circle-plus me-2"></i>
              Sürücü əlavə et
            </button>
            <div className="edit">
              <button
                className="px-3 bg-warning me-3"
                disabled={selectedItem.length !== 1}
                onClick={() => handleEdit()}
              >
                <i className="fa-regular fa-pen-to-square"></i>
              </button>
              <button className="px-3 bg-danger" onClick={() => handleDelete()}>
                <i className="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="main">

        <Table
          data={driverList}
          names={names}
          columns={placeholders}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>

      <CRUDModal
        names={names}
        isOpen={isOpen}
        putMode={edit}
        setOpen={setOpen}
        selectedItem={selectedItem}
        placeholders={placeholders}
        formData={formData}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
    </div>
  );
};

export default Drivers;
