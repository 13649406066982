import './style.css'

const CRUDModal = ({
    isOpen,
    formData,
    setFormData,
    putMode,
    names,
    placeholders,
    handleCancel,
    handleSubmit
}) => {
    
    const handleChange = e => {
        const { name, value } = e.target;

        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }


    return (
        <div id='Modal' className={isOpen ? 'active' : ''}>
            <div className="modal-box px-2">
                <div className="modal-header">
                    <span className='text-center w-100 text-white'>{putMode ? 'Düzəliş et' : 'Əlavə et'}</span>

                    <div className="close" onClick={handleCancel}>
                        <i className="fa-solid fa-xmark text-white"></i>
                    </div>
                </div>


                <div className='field-wrapper'>
                    {
                        names?.map((item, i) => (
                            (item === "statusCargo" || item === "statusTruck") ?
                                <select
                                    className="form-select"
                                    key={i}
                                    name={item}
                                    onChange={handleChange}
                                    value={formData[item]}
                                >
                                    <option value="">Status seçin</option>
                                    <option value="loadless">Yüksüz</option>
                                    <option value="goingLoading">Yükləməyə gedir</option>
                                    <option value="inLoading">Yükləmədə</option>
                                    <option value="loaded">Yüklü</option>
                                    <option value="inDestination">Təyinata çatdı(təhvil verilmədə)</option>
                                    {/* <option value="unLoading">Boşalmada</option> */}
                                    <option value="delivered">Təhvil Verildi</option>
                                </select>
                                : item === "role" ?
                                    <select
                                        className="form-select"
                                        key={i}
                                        name={item}
                                        onChange={handleChange}
                                        value={formData[item]}
                                    >
                                        <option value="" disabled>Səlahiyyət</option>
                                        <option value="user">İstifadəçi</option>
                                        <option value="observer">Müşahidəçi</option>
                                        <option value="admin">Admin</option>
                                    </select>
                                    :
                                    <input
                                        type="text"
                                        placeholder={placeholders[i]}
                                        name={item}
                                        value={formData[item]}
                                        onChange={handleChange}
                                        key={i} />
                        ))
                    }

                </div>


                <div className='buttons w-100 d-flex justify-content-between'>
                    <button onClick={handleCancel} className="">Cancel</button>
                    <button onClick={handleSubmit} className=" bg-current">{putMode ? 'Update' : 'Create'}</button>
                </div>
            </div>
        </div>
    )
}

export default CRUDModal