import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import "./style.css";

const TruckInformation = () => {
  const { truckList, cargoList, driverList, selectedTruck, setSelectedTruck } =
    useContext(AppContext);
  const [truckInfo, setTruckInfo] = useState(null);
  const [cargoInfo, setCargoInfo] = useState(null);
  const [driverInfo, setDriverInfo] = useState(null);

  useEffect(() => {
    selectedTruck &&
      setTruckInfo(
        truckList?.find(
          (item) => item.truckCode === selectedTruck[0]?.lmsg.unit_id.toString()
        )
      );
    selectedTruck &&
      setCargoInfo(
        cargoList?.find(
          (item) =>
            item.statusCargo !== "delivered" &&
            item.truckCode === selectedTruck[0]?.lmsg.unit_id.toString()
        )
      );
    selectedTruck &&
      setDriverInfo(
        driverList?.find(
          (item) => item.truckCode === selectedTruck[0]?.lmsg.unit_id.toString()
        )
      );
  }, [selectedTruck, truckList, driverList, cargoList]);
  const colors = {
    loadless: "text-danger",
    goingLoading: "text-secondary",
    inLoading: "text-secondary",
    loaded: "text-warning",
    inDestination: "text-warning",
    delivered: "text-success",
  };
  const translate = {
    loadless: "Yüksüz",
    goingLoading: "Yükləməyə gedir",
    inLoading: "Yükləmədə",
    loaded: "Yüklü",
    inDestination: "Təyinata çatdı(Təhvil verilmədə)",
    delivered: "Təhvil verildi",
  };

  return (
    <div id="TruckInformation" className={selectedTruck ? "active" : ""}>
      <div className="close" onClick={() => setSelectedTruck(null)}>
        <i className="fa-solid fa-xmark"></i>
      </div>
      {truckInfo && driverInfo && selectedTruck ? (
        <>
          <ul className="truck">
            <h3>Nəqliyyat vasitəsinə dair məlumatlar</h3>

            <li className="d-flex align-items-center">
              <span>N.v.-nin halı</span>
              {selectedTruck[0]?.lmsg.speed !== 0 ? (
                <span className="d-flex flex-column status in-road">
                  <span>Hərəkətdə</span>
                  <span>{selectedTruck[0]?.lmsg.speed} km/saat</span>
                </span>
              ) : (
                <span className="text-danger status parking">Park halında</span>
              )}
            </li>
            <li>
              <span>N.v.-nin növü</span>

              <span>{truckInfo?.truckType}</span>
            </li>
            <li>
              <span>N.v.-nin sahibi</span>

              <span>{truckInfo?.truckOwner}</span>
            </li>
            <li>
              <span>Dartıcının nömrəsi</span>

              <span>{truckInfo?.pullerNumber}</span>
            </li>
            <li>
              <span>Qoşqunun nömrəsi</span>

              <span>{truckInfo?.harnessNumber}</span>
            </li>
          </ul>
          <ul className="driver">
            <h3>Sürücüyə dair məlumatlar</h3>

            <li>
              <span>Sürücünün adı</span>

              <span>{driverInfo?.firstName}</span>
            </li>
            <li>
              <span>Sürücünün soyadı</span>

              <span>{driverInfo?.lastName}</span>
            </li>
          </ul>
          {cargoInfo ? (
            <ul className="cargo">
              <h3>Yükə dair məlumatlar</h3>

              <li>
                <span>Yükün adı</span>

                <span>{cargoInfo?.cargoName}</span>
              </li>
              <li>
                <span>Yükün çəkisi/miqdarı</span>

                <span>{cargoInfo?.cargoWeight}</span>
              </li>
              <li>
                <span>Yükün mənşəyi</span>

                <span>{cargoInfo?.originOfCargo}</span>
              </li>
              <li>
                <span>Yükün təyinatı</span>

                <span>{cargoInfo?.destinationOfCargo}</span>
              </li>
              {
                <div>
                  <span>Status</span>

                  <span className={colors[cargoInfo?.statusCargo]}>
                    {translate[cargoInfo?.statusCargo]}
                  </span>
                </div>
              }
            </ul>
          ) : (
            <h4 className="text-center status warning">Yüksüz</h4>
          )}
        </>
      ) : (
        <h3 className="px-4 py-3 text-center text-danger">
          Məlumat mövcud deyil
        </h3>
      )}
    </div>
  );
};

export default TruckInformation;
