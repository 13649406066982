import { useContext, useState } from 'react'
import toast from 'react-hot-toast'
import { AppContext } from '../../../App'
import CRUDModal from '../../../components/CRUDModal'
import Table from '../../../components/Table'
import { del, post, put } from '../../../utils/api'

import '../style.css'


const initialValue = {
    truckCode: "",
    truckType: "",
    truckOwner: "",
    contractNumber: "",
    pullerMark: "",
    pullerYear: "",
    pullerNumber: "",
    harnessMark: "",
    harnessYear: "",
    harnessNumber: "",
    statusTruck: "",
}

const names = [
    "truckCode",
    "truckType",
    "truckOwner",
    "contractNumber",
    "pullerMark",
    "pullerYear",
    "pullerNumber",
    "harnessMark",
    "harnessYear",
    "harnessNumber",
    "statusTruck",
]

const placeholders = [
    "TIR-ın kodu",
    "TIR-ın növü",
    "TIR-ın sahibi",
    "TIR sahibi ilə bağlanılmış müqavilənin N",
    "Dartıcının markası",
    "Dartıcının istehsal ili",
    "Dartıcının nömrəsi",
    "Qoşqunun markası",
    "Qoşqunun istehsal ili",
    "Qoşqunun nömrəsi",
    "Tırın statusu"
]

const Trucks = () => {
    const { user, truckList, getTruckList } = useContext(AppContext);
    const [isOpen, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [formData, setFormData] = useState(initialValue);

    const [selectedItem, setSelectedItem] = useState([])
    const handleDelete = async () => {
        try {
            await del(`/truck`, selectedItem)
            getTruckList()
            setSelectedItem([])
            toast.success(`Uğurla silindi!`)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleEdit = async () => {
        await setEdit(true)
        await setFormData(truckList.find((item) => item._id === selectedItem[0]))
        setOpen(true)
    }
    const handleCancel = () => {
        setOpen(false);
        setFormData(initialValue);
        setEdit(false);
    }

    const handleSubmit = async () => {
        handleCancel();
        try {
            if (edit) {
                await put(`/truck`, { id: selectedItem[0], data: formData })
                getTruckList();
                return toast.success(`Uğurla dəyişdirildi!`);
            }
            await post(`/truck`, formData);
            setFormData(initialValue);
            getTruckList();
            toast.success(`Uğurla əlavə olundu!`);
        }

        catch (error) {
            toast.error(error.message)
        }
    }


    return (
        <div id='Dashboard' className='px-3'>
            <div className="add-buttons py-3">
            {user?.role === "admin" && (
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="bg-current px-3 py-2 h-auto font-14"
              onClick={() => !edit && setOpen(true)}
            >
              <i className="fa-solid fa-circle-plus me-2"></i>
              Tır əlavə et
            </button>
            <div className="edit">
              <button
                className="px-3 bg-warning me-3"
                disabled={selectedItem.length !== 1}
                onClick={() => handleEdit()}
              >
                <i className="fa-regular fa-pen-to-square"></i>
              </button>
              <button className="px-3 bg-danger" onClick={() => handleDelete()}>
                <i className="fa-solid fa-trash"></i>
              </button>
            </div>
          </div>
        )}
            </div>

            <div className="main">
                <Table
                    data={truckList}
                    names={names}
                    columns={placeholders}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                />

            </div>

            <CRUDModal
                names={names}
                isOpen={isOpen}
                putMode={edit}
                setOpen={setOpen}
                selectedItem={selectedItem}
                placeholders={placeholders}
                formData={formData}
                setFormData={setFormData}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
            />
        </div >
    )
}

export default Trucks