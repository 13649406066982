import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { AppContext } from "../../../App";
import CRUDModal from "../../../components/CRUDModal";
import Table from "../../../components/Table";
import { del, put } from "../../../utils/api";

import "../style.css";

const initialValue = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  role: "",
  truckCode: "",
};

const names = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "role",
  "truckCode",
];

const placeholders = [
  "İstifadəçinin Adı",
  "İstifadəçinin Soyadı",
  "İstifadəçinin E-maili",
  "İstifadəçinin Nömrəsi",
  "İstifadəçinin Səlahiyyəti",
  "İzləyəcəyi Tırın Kodu",
];

const Users = () => {
  const { users, getUsers } = useContext(AppContext);
  const [isOpen, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState(initialValue);

  const [selectedItem, setSelectedItem] = useState([]);
  const handleDelete = async () => {
    try {
      await del(`/users`, selectedItem);
      getUsers();
      setSelectedItem([]);
      toast.success(`Uğurla silindi!`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async () => {
    await setEdit(true);
    await setFormData(users.find((item) => item._id === selectedItem[0]));
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setFormData(initialValue);
    setEdit(false);
  };
  const handleSubmit = async () => {
    handleCancel();
    try {
      if (edit) {
        await put(`/users`, { id: selectedItem[0], data: formData });
        getUsers();
        return toast.success(`Uğurla dəyişdirildi!`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div id="Dashboard" className="px-3">
      <div className="main">
        <div className="top d-flex justify-content-end py-3">
          <div className="edit">
            <button
              className="px-3 bg-warning me-3"
              disabled={selectedItem.length !== 1}
              onClick={() => handleEdit()}
            >
              <i className="fa-regular fa-pen-to-square"></i>
            </button>
            <button className="px-3 bg-danger" onClick={() => handleDelete()}>
              <i className="fa-solid fa-trash"></i>
            </button>
          </div>
        </div>

        <Table
          data={users}
          names={names}
          columns={placeholders}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </div>

      <CRUDModal
        names={names}
        putMode={edit}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        setOpen={setOpen}
        entity={"users"}
        selectedItem={selectedItem}
        placeholders={placeholders}
        initialValue={edit ? formData : initialValue}
        setEdit={setEdit}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
};

export default Users;
