import { useContext } from "react";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../App";
import { get } from "../../utils/api";
import { removeValue } from "../../utils/storage";
import {
  CargoIcon,
  DriverIcon,
  HesabatIcon,
  HomeIcon,
  ProqnozIcon,
  TruckIcon,
  TruckMovementsIcon,
  UserIcon,
} from "../Icons/Icons";
import "./style.css";
import logo from "../../assets/img/logo.png";
import { APP_VERSION } from "../../constants";

const Navbar = () => {
  const { setUser, user } = useContext(AppContext);

  const handleLogout = async () => {
    // eslint-disable-next-line
    const data = await get("/logout");
    removeValue("@token");
    setUser(null);
    toast.success("Çıxış edildi!");
  };
  const mainPages = [
    {
      icon: HomeIcon,
      title: "Ana səhifə",
      url: "/",
    },
    {
      icon: HesabatIcon,
      title: "Hesabat",
      url: "/",
    },
    {
      icon: ProqnozIcon,
      title: "Proqnoz",
      url: "/",
    },
  ];
  const subPages = [
    {
      icon: TruckIcon,
      title: "Tırlar",
      url: "dashboard/trucks",
    },
    {
      icon: CargoIcon,
      title: "Yüklər",
      url: "/dashboard/cargos",
    },
    {
      icon: DriverIcon,
      title: "Sürücülər",
      url: "/dashboard/drivers",
    },
    {
      icon: TruckMovementsIcon,
      title: "Tırın hərəkətləri",
      url: "/dashboard/stored-truck",
    },
    {
      icon: UserIcon,
      title: "İstifadəçilər",
      url: "/dashboard/users",
    },
  ];
  return (
    <div id="Navbar">
      <div className="logo">
        <img src={logo} height="35" alt="" />
      </div>

      <ul className="pages">
        {mainPages.map((p, i) => (
          <li key={i}>
            <NavLink to={p.url}>
              <img src={p.icon} alt="" />
              <span>{p.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>

      {user.role === "admin" && (
        <ul className="pages">
          {subPages.map((p, i) => (
            <li key={i}>
              <NavLink to={p.url}>
                <img src={p.icon} alt="" />
                <span>{p.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
      <button
        className="logout bg-white px-3 d-flex align-items-center"
        onClick={() => handleLogout()}
      >
        <i className="fa-solid fa-arrow-right-from-bracket"></i>
        <span className="ms-2">Çıxış et</span>
      </button>
      <span className="px-3" style={{fontSize: 12}}>Version: {APP_VERSION}</span>
    </div>
  );
};

export default Navbar;
