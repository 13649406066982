import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { AppContext } from '../../App';
import { post } from '../../utils/api';
import { setValue } from '../../utils/storage';
import './style.css'
import { EyeSlashIcon } from '../../components/Icons/Icons'




const Login = () => {
    const { setUser } = useContext(AppContext);
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [passType, setPassType] = useState(true);

    const handleSubmit = async () => {
        try {
            const { access_token, data } = await post('/login', formData)
            // console.log(data)
            setValue("@token", access_token)
            setUser(data)
            toast.success("Xoş gəldiniz")
        }
        catch (error) {
            toast.error(error.message)
        }
    }




    return (
        <div id='Login' className='d-flex justify-content-center align-items-center'>
            <div className="col-md-6 p-2 h-100 d-none d-md-block">
                <div className='w-100 h-100 bg-current rounded-3'>

                </div>
            </div>
            <div className="col-md-6 col-12 d-flex justify-content-center align-items-center">
                <div className="auth-box px-2 py-3">
                    <h5 className='text-center mb-5'>Xoş Gəlmisiniz</h5>
                    <div className="form-group">
                        <input type="email" className="form-control" id="email" placeholder="E-mail"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <input
                            type={passType ? "password" : "text"}
                            className="form-control"
                            id="password"
                            placeholder="Şifrə"
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        />
                        <img
                            src={EyeSlashIcon}
                            alt="hidden and show password icon"
                            className='icon'
                            onClick={() => setPassType(!passType)}
                        ></img>
                    </div>

                    <button className='bg-current px-3 w-100 mt-5'
                        onClick={() => handleSubmit()}
                    >
                        Giriş et
                    </button>


                    <span className='mt-3 d-block text-center'>Hesabın yoxdursa, <Link to='/register' style={{ color: "#FF7B51" }}>Qeydiyyatdan keç.</Link></span>
                </div>
            </div>
        </div>
    )
}

export default Login;