import "./style.css";

const Table = ({ columns, names, data, selectedItem, setSelectedItem }) => {
  const colors = {
    loadless: "text-danger",
    goingLoading: "text-secondary",
    inLoading: "text-secondary",
    loaded: "text-warning",
    inDestination: "text-warning",
    delivered: "text-success",
  };
  const translate = {
    loadless: "Yüksüz",
    goingLoading: "Yükləməyə gedir",
    inLoading: "Yükləmədə",
    loaded: "Yüklü",
    inDestination: "Təyinata çatdı(Təhvil verilmədə)",
    delivered: "Təhvil verildi",
  };

  const handleChecked = (e) => {
    setSelectedItem(data.map((item) => item._id));
    if (!e.target.checked) {
      setSelectedItem([]);
    }
  };
  const handleChange = (e, id) => {
    if (e.target.checked) {
      if (!selectedItem.find((el) => el === id))
        setSelectedItem([...selectedItem, id]);
    } else {
      if (!selectedItem.find((el) => el === id))
        setSelectedItem([...selectedItem, id]);
      else setSelectedItem(selectedItem.filter((el) => el !== id));
    }
  };

  return (
    <div id="Table" className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(e) => handleChecked(e)}
              />
            </th>
            {columns?.map((item) => (
              <th scope="col" key={item}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => (
            <tr key={i}>
              <th scope="row">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={
                    selectedItem.find((el) => el === item._id) ? true : false
                  }
                  onChange={(e) => handleChange(e, item._id)}
                />
              </th>
              {names?.map((el, j) => (
                <td
                  key={j}
                  className={
                    el === "statusCargo" || el === "statusTruck"
                      ? colors[item[el]]
                      : ""
                  }
                >
                  {el === "statusCargo" || el === "statusTruck"
                    ? translate[item[el]]
                    : item[el]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
