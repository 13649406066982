import { useContext } from "react";
import { AppContext } from "../../App";
import {
  AllTrucksIcon,
  FilterIcon,
  LoadedIcon,
  LoadlessIcon,
} from "../Icons/Icons";
import "./style.css";

const Header = () => {
  const { trucks, truckList, user, filter, setFilter } = useContext(AppContext);

  return (
    <div id="Header">
      <div className="d-flex top-header">
        <button type="button" onClick={() => setFilter(!filter)}>
          <img src={FilterIcon} alt="" />
        </button>
        <h2>
          {user.firstName} {user.lastName}
        </h2>
      </div>
      <div className="d-flex flex-row justify-content-center w-100">
        <div className="transportation d-flex align-items-center">
          <div className="pe-3 me-2 border-end">
            <img src={AllTrucksIcon} alt="" />
            <span>Sistemdə olan nəqliyyat vasitələrinim ümumi sayı</span>
            <span className="count">{trucks?.length}</span>
          </div>
          <div className="pe-3 me-2 border-end">
            <img src={LoadlessIcon} alt="" />
            <span>Yüksüz olan nəqliyyat vasitələrinin sayı</span>
            <span className="count">
              {truckList?.filter((t) => t.statusTruck === "loadless").length}
            </span>
          </div>
          <div className="pe-3 me-2">
            <img src={LoadedIcon} alt="" />
            <span>Yüklü olan nəqliyyat vasitələrinin sayı</span>
            <span className="count">
              {truckList?.filter((t) => t.statusTruck === "loaded").length}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
