// eslint-disable-next-line
import { useContext } from "react";
import { AppContext } from "../../App";
import Filter from "../../components/Filter";
// import Filter from "../../components/Filter";
// import LeafletMap from "../../components/Map";
import TruckInformation from "../../components/TruckInformation";
import YandexMap from "../../components/YandexMap";
import "./style.css";

const Home = () => {
  const { user, trucks } = useContext(AppContext);
  return (
    <div id="Home">
      <div className="map">
        {/* {trucks && <LeafletMap currentPosition={position} />} */}

        {trucks && <YandexMap />}
        {user.role === "admin" ? <TruckInformation /> : ""}
        <Filter />
      </div>
    </div>
  );
};

export default Home;
