import { useState } from "react";
import toast from "react-hot-toast";
import Table from "../../../components/Table";
import { post } from "../../../utils/api";

import "../style.css";

const names = ["truckCode", "address", "lat", "lng", "speed", "createdAt"];

const placeholders = [
  "TIR-ın kodu",
  "Tırın adresi",
  "lat",
  "lng",
  "Sürət",
  "Tarix",
];

const StoredTruck = () => {
  const [input, setInput] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [data, setData] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const handleSubmit = async () => {
    setSpinner(true);
    try {
      const { data } = await post("/get-stored-truck", { truckCode: input });
      setData(data);
      setSpinner(false);
      return toast.success("Hərəkətlər uğurla tapıldı");
    } catch (err) {
      setData(null);
      console.log(err);
      setSpinner(false);
      return toast.error("Kodun düzgünlüyünü yoxlayın");
    }
  };
  return (
    <div id="Dashboard" className="px-3">
      <div className="main">
        <div className="form-group d-flex py-3">
          <input
            type="text"
            placeholder="Tırın kodunu girin..."
            className="form-control w-auto me-2"
            onChange={(e) => setInput(e.target.value)}
            value={input}
          />
          <button className="bg-current px-4" onClick={handleSubmit}>
            {spinner ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
              ></span>
            ) : (
              "Axtar"
            )}
          </button>
        </div>

        {data && (
          <Table
            data={data}
            names={names}
            columns={placeholders}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        )}
      </div>
    </div>
  );
};

export default StoredTruck;
