import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import "./style.css";

const Filter = () => {
  const {
    trucks,
    selectedTruck,
    setFilterActivate,
    truckList,
    setZoomOut,
    cargoList,
    setFilteredData,
    filter,
    setFilter,
  } = useContext(AppContext);
  const truckType = truckList?.map((i) => i.truckType || "");
  const truckOwner = truckList?.map((i) => i.truckOwner || "");
  const cargoName = cargoList?.map((i) =>
    i.statusCargo !== "delivered" ? i.cargoName : ""
  );
  const originOfCargo = cargoList?.map((i) =>
    i.statusCargo !== "delivered" ? i.originOfCargo : ""
  );
  const destinationOfCargo = cargoList?.map((i) =>
    i.statusCargo !== "delivered" ? i.destinationOfCargo : ""
  );

  useEffect(() => {
    if (selectedTruck) return setFilter(false);
    // eslint-disable-next-line
  }, [selectedTruck]);

  const [options, setOptions] = useState({
    truckType: "",
    truckOwner: "",
    cargoName: "",
    originOfCargo: "",
    destinationOfCargo: "",
  });

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setOptions((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const resetFilter = () => {
    setOptions({
      truckType: "",
      truckOwner: "",
      cargoName: "",
      originOfCargo: "",
      destinationOfCargo: "",
    });

    setFilteredData(trucks);
    setFilterActivate(false);
  };

  const handleSubmit = async () => {
    setFilterActivate(true);
    setZoomOut(true);

    let TruckList = truckList
      .filter((el) =>
        el.truckType
          ?.toString()
          .toLowerCase()
          .includes(options?.truckType.toLowerCase())
      )
      .filter((el) =>
        el.truckOwner
          ?.toString()
          .toLowerCase()
          .includes(options?.truckOwner.toLowerCase())
      );

    let CargoList = cargoList
      .filter((el) =>
        el.cargoName
          ?.toString()
          .toLowerCase()
          .includes(options?.cargoName.toLowerCase())
      )
      .filter((el) =>
        el.originOfCargo
          ?.toString()
          .toLowerCase()
          .includes(options?.originOfCargo.toLowerCase())
      )
      .filter((el) =>
        el.destinationOfCargo
          ?.toString()
          .toLowerCase()
          .includes(options?.destinationOfCargo.toLowerCase())
      );
    const filtered = trucks
      .filter((el) => {
        return TruckList.find((t) => {
          // eslint-disable-next-line
          return el.lmsg.unit_id == t.truckCode;
        });
      })
      .filter((el) => {
        return CargoList.find((c) => {
          // eslint-disable-next-line
          return el.lmsg.unit_id == c.truckCode;
        });
      });
    setFilteredData(filtered);
  };
  // console.log("1", truckList);
  // console.log("2", cargoList);
  // console.log("filtered", filteredData);

  return (
    <div id="Filter" className={filter ? "active" : ""}>
      <div className="content">
        <h5 className="mb-3 border-bottom pb-2 w-100 text-center mt-2">
          Filterlə
        </h5>

        {/* 
        <div className="form-group mb-3">
          <input type="email" className="form-contol" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Axtarın . . ." />
          <small id="emailHelp" className="form-text text-muted">Maşın nömrəsi və sürücüyə görə axtarış</small>
        </div> 
        */}

        <div className="form-group w-100 mb-2">
          <label className="w-100">
            Nəqliyyatın vasitəsinin növü
            <select
              onChange={handleChange}
              name="truckType"
              value={options?.truckType}
              className="form-select w-100 mt-1"
            >
              <option value="">Seçin</option>
              {[
                ...new Set(
                  truckType?.filter((t) => t !== undefined && t !== "")
                ),
              ]?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="form-group w-100 mb-2">
          <label className="w-100">
            Nəqliyyat vasitəsinin sahibi
            <select
              name="truckOwner"
              onChange={handleChange}
              value={options?.truckOwner}
              className="form-select w-100 mt-1"
            >
              <option value="">Seçin</option>
              {[
                ...new Set(
                  truckOwner?.filter((t) => t !== undefined && t !== "")
                ),
              ]?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="form-group w-100 mb-2">
          <label className="w-100">
            Yük
            <select
              name="cargoName"
              onChange={handleChange}
              value={options?.cargoName}
              className="form-select w-100 mt-1"
            >
              <option value="">Seçin</option>
              {[
                ...new Set(
                  cargoName?.filter((t) => t !== undefined && t !== "")
                ),
              ]?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="form-group w-100 mb-2">
          <label className="w-100">
            Yükün Mənşəyi
            <select
              name="originOfCargo"
              onChange={handleChange}
              value={options?.originOfCargo}
              className="form-select w-100 mt-1"
            >
              <option value="">Seçin</option>
              {[
                ...new Set(
                  originOfCargo?.filter((t) => t !== undefined && t !== "")
                ),
              ]?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        </div>

        <div className="form-group w-100 mb-2">
          <label className="w-100">
            Yükün Təyinatı
            <select
              name="destinationOfCargo"
              onChange={handleChange}
              value={options?.destinationOfCargo}
              className="form-select w-100 mt-1"
            >
              <option value="">Seçin</option>
              {[
                ...new Set(
                  destinationOfCargo?.filter((t) => t !== undefined && t !== "")
                ),
              ]?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        </div>

        <button
          onClick={() => handleSubmit()}
          className="py-2 bg-current d-flex align-items-center justify-content-center w-100"
        >
          Axtar
        </button>
        <button
          onClick={() => resetFilter()}
          className="py-2 mt-2 btn btn-outline-danger d-flex align-items-center justify-content-center w-100"
        >
          Filteri Sıfırla
        </button>
      </div>
    </div>
  );
};

export default Filter;
